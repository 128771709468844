<template>
  <v-row align="center" justify="center" class="mt-10">
    <v-col cols="12" class="header mb-2 pl-2 py-4">
      <span style="font-size: 24px; font-weight: 400">{{
        $t("changepassword.changepassword")
      }}</span>
    </v-col>
    <v-card outlined width="100vw" class="pa-4">
      <v-row>
        <v-col cols="12" md="6">
          <span>{{ $t("changepassword.oldpass") }}</span>
          <v-text-field
            v-model="oldPassword"
            solo
            dense
            hide-details="auto"
            type="password"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <span>{{ $t("changepassword.newpass") }}</span>
          <v-text-field
            v-model="newPassword"
            solo
            dense
            hide-details="auto"
            type="password"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-btn @click="updatePassword">{{
            $t("changepassword.changepassword")
          }}</v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-row>
</template>

<script>
import { Decode } from "@/services";

export default {
  components: {},
  data() {
    return {
      oldPassword: "",
      newPassword: "",
    };
  },
  created() {
    const lang = localStorage.getItem("lang");
    if (lang == "en") {
      this.EngLang = true;
      this.itemChangeLang = "lastname";
    } else if (lang == "th") {
      this.EngLang = false;
      this.itemChangeLang = "firstname";
    }
  },
  mounted() {},
  methods: {
    async updatePassword() {
      this.$swal({
        text: this.EngLang
          ? "Do you want to change password ?"
          : "คุณต้องการเปลี่ยนรหัสผ่าน หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.EngLang ? "Cancel" : "ยกเลิก",
        confirmButtonText: this.EngLang ? "Yes" : "ใช่",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            this.userData = JSON.parse(
              Decode.decode(localStorage.getItem("userCUdata"))
            );
            const auth = {
              headers: {
                Authorization: `Bearer ${this.userData.token}`,
              },
            };
            const data = {
              id: this.userData.id,
              oldPassword: this.oldPassword,
              newPassword: this.newPassword,
            };
            const response = await this.axios.put(
              `${process.env.VUE_APP_API}/user/changepassword/${this.userData.id}`,
              data,
              auth
            );

            console.log("response", response);

            if (response.data.response_status == "SUCCESS") {
              this.$swal.fire({
                icon: "success",
                text: this.$t("changepassword.success"),
                showConfirmButton: false,
                timer: 1000,
              });
            } else {
              this.$swal.fire({
                icon: "error",
                text: response.data.message,
                showConfirmButton: false,
                timer: 2000,
              });
            }
          } catch (error) {
            console.error(error);
            this.$swal.fire({
              icon: "error",
              text: this.$t("changepassword.checkpass"),
              showConfirmButton: false,
              timer: 3000,
            });
          }
        }
      });
    },

    convertDate(val) {
      return moment(val).format("DD/MM/yyyy");
    },

    async DeleteItem(id) {
      this.$swal({
        text: this.EngLang
          ? "Do you want to delete ?"
          : "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.EngLang ? "Cancel" : "ยกเลิก",
        confirmButtonText: this.EngLang ? "Yes" : "ต้องการลบข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.userData = JSON.parse(
            Decode.decode(localStorage.getItem("userCUdata"))
          );
          const auth = {
            headers: {
              Authorization: `Bearer ${this.userData.token}`,
            },
          };
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/user/${id}`,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAll();
        }
      });
    },
  },
};
</script>

<style scoped>
.header {
  background-color: #de5c8e;
  padding: 10px;

  color: white;
  border-radius: 5px;
}
</style>